.section {
  &__aboutus {
    padding: 0 5rem;
    border: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__services {
    width: 100%;
    max-width: 100rem;
    &--title {
      margin-top: calc(#{$nav-header-connect} + #{$nav-height}+ 2rem);
    }

    @include respond(tab-port) {
      max-width: 80rem;
    }
  }
}

.aboutus-container {
  margin-top: calc(#{$nav-header-connect} + #{$nav-height} + 2rem);
  height: 70vh;
  width: 100%;
  max-width: 100rem;
  background-color: $color-white;
  position: relative;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 0 10px 0px $color-dark-grey;
  background-image: linear-gradient(
      to right bottom,
      rgba($color-lightest-grey, 0),
      rgba($color-lightest-grey, 0)
    ),
    url(../assets/images/IMG_0003-lg.jpg);
  background-size: cover;
  background-position: center;

  @include respond(tab-land) {
    height: 45rem;
    margin-top: calc(#{$nav-header-connect} + #{$nav-height} + 4rem);
  }

  @include respond(phone) {
    justify-content: center;
    align-items: center;
    height: 60rem;
  }

  &__photo {
    height: auto;
    width: 50%;

    @include respond(tab-port) {
      width: 75%;
      margin-bottom: 2rem;
    }

    &--file {
      position: relative;
      border-radius: 50%;
      height: 20rem;
      width: 20rem;
      overflow: clip;
    }

    @include respond(phone-large) {
      display: none;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20% 10%;
    width: 60%;
    height: 100%;
    -webkit-clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
    background-image: linear-gradient(
      to right bottom,
      rgba($color-lightest-grey, 0.8),
      rgba($color-lightest-grey, 0.8)
    );

    &--title {
      font-family: $default-font;
      text-transform: uppercase;
      font-size: 4rem;
      margin-bottom: 4rem;
    }

    &--paragraph {
      font-family: $cursive-font;
      font-size: 2.2rem;
      font-style: italic;
      text-align: justify;
    }

    @include respond(phone) {
      height: 40rem;
      width: 100%;
      -webkit-clip-path: polygon(0 5rem, 100% 3rem, 100% 38rem, 0 100%);
      clip-path: polygon(0 5rem, 100% 3rem, 100% 38rem, 0 100%);
    }
  }
}

.services {
  display: flex;
  align-items: center;
  margin: 3rem 0;
  max-width: 100rem;

  @include respond(phone) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__heading {
    text-transform: uppercase;
    font-family: $default-font;
    font-size: 2.2rem;
    color: $color-black;
    padding: 2rem 2rem;
    width: 100%;
    height: 25%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-image: linear-gradient(
      to right bottom,
      rgba($color-lightest-grey, 0.8),
      rgba($color-lightest-grey, 0.8)
    );

    &--back {
      background-image: none;
      height: auto;
    }
  }

  &__container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    transition: 0.3s ease-in;

    @include respond(tab-land) {
      flex-wrap: wrap;
      gap: 1rem;
    }

    @include respond(phone) {
      width: 90%;
      margin-bottom: 2rem;
    }
  }

  &__photo {
    max-width: 90%;
    height: 45vh;
    width: 30rem;
    border-radius: 2px;
  }

  &__text {
    font-family: $cursive-font;
    font-size: 2rem;
    margin: 2rem;

    @include respond(tab-land) {
      font-size: 1.5rem;
    }

    @include respond(phone) {
      text-align: center;
      font-family: $default-font;
      font-style: normal;
    }
  }
}

.other-services {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  &__list {
    padding: 2rem;
    list-style: none;

    @include respond(phone) {
      width: 90%;
      margin-top: -2rem;
    }

    &--item {
      font-family: $cursive-font;
      font-size: 2rem;

      @include respond(tab-land) {
        font-size: 1.5rem;
      }

      @include respond(phone) {
        text-align: center;
        font-family: $default-font;
        font-style: normal;
      }
    }
  }
}
