.header {
  position: fixed;
  top: 0;
  background: linear-gradient($color-primary, $color-primary-light);
  color: $color-white;
  height: $nav-header-connect;
  width: 100vw;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  text-shadow: 0 1px 7px $color-black;

  @include respond(phone) {
    flex-direction: column;
    height: auto;
    padding-top: 1rem;
    flex-wrap: wrap;
  }

  &__logo {
    display: inline-block;
    position: relative;
    color: $color-white;
    text-decoration: none;
    animation: $header-animation;
    margin: 0 1.5rem;

    &-name {
      font-family: $default-font;
      font-size: 3.2rem;
      border: solid;
      border-width: 0.5rem;
      border-color: $color-white;
      padding: 1rem 0.5rem 0.2rem 0.5rem;

      @include respond(tab-port) {
        font-size: 2.5rem;
      }
    }

    &-description {
      font-size: 1.6rem;
      font-family: $default-font;
      font-style: italic;
      font-weight: 500;
      padding-top: 0.6rem;

      @include respond(tab-port) {
        font-size: 1.2rem;
      }
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: $color-light-grey;
    margin-right: 1rem;
  }
}
