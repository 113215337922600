@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 1em = 16px */

@mixin respond($breakpoint) {
  @if $breakpoint == skinny {
    @media only screen and (max-width: 22.5em) {
      @content;
    } //360px
  }

  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint == phone-large {
    @media only screen and (max-width: 50.5em) {
      @content;
    } //808px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 65.625em) {
      @content;
    } //1050px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px
  }

  @if $breakpoint == horiz-phone {
    @media only screen and (max-height: 25em) {
      @content;
    } //400px height
  }
}
