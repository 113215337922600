*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 55%;
  }
}

body {
  box-sizing: border-box;
  background-color: $color-primary;
}
