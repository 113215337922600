.tagline-container {
  background-image: linear-gradient(
      to right bottom,
      rgba($color-lightest-grey, 0.7),
      rgba($color-lightest-grey, 0.7)
    ),
    url(../assets/images/IMG_3265.jpg);
  background-size: cover;
  background-position: 0 25%;
  margin-top: $nav-header-connect;
  min-height: calc(100vh - #{$nav-header-connect} - #{$nav-height});
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include respond(tab-land) {
    flex-direction: column;
  }

  &__text {
    min-width: 50%;
    font-family: $cursive-font;
    font-style: italic;
    font-size: 3.5rem;
    text-align: center;
    align-self: center;
    color: $color-primary;
    text-shadow: 0 0 10px $color-dark-grey;
  }
  &__collage {
    display: flex;
    justify-content: center;
    max-width: 40%;
    &--photo {
      width: 20%;
      box-shadow: 0 1.5rem 4rem rgba($color-black, 0.4);
      border-radius: 2px;
      position: relative;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.4) translateY(-0.5rem);
        box-shadow: 0 2.5rem 4rem rgba($color-black, 0.5);
        z-index: 20;
      }
    }

    &:hover &__photo:not(:hover) {
      transform: scale(0.6);
    }
  }
}

.picture-border {
  display: flex;
  margin-left: 24rem;

  &__photo {
    width: 100%;
    display: block;
    max-height: 25rem;
    justify-content: center;
  }
}
