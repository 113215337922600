.footer {
  padding: 4rem 0;
  font-size: 4rem;
  color: $color-light-grey;
  background-color: $color-dark-grey;
  z-index: 1003;
  position: relative;

  @include respond(tab-port) {
    margin-bottom: 4rem;
  }

  &__site-map {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;
  }

  &__certifications {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;
  }

  &__social-media {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 4rem;

    &--logo:hover {
      fill: white;
    }
  }
}
