.collage {
  &__photo {
    height: auto;
    width: 30%;
    border-radius: 5px;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.5, 1.5);
      box-shadow: 0rem 0rem 2rem $color-black;
    }
  }
}
