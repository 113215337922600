.head-nav {
  display: flex;

  @include respond(tab-port) {
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    transition: background-color 0.1s;
  }

  &__item:active::before {
    background-color: $color-primary-light;
  }

  &__link:link,
  &__link:visited {
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 2rem;
    font-style: italic;
    font-weight: bold;
    display: block;
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;

    @include respond(tab-port) {
      font-size: 1.2rem;
      padding: 1.5rem 2rem;
    }

    @include respond(phone-large) {
      &:not(:last-child) {
        display: none;
      }
    }
    @include respond(phone) {
      font-size: 1.5rem;
      padding: 1rem 1rem;
    }

    &:hover {
      background-color: $color-primary-light;
      transform: translateY(-0.1rem);
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: currentColor;

    @include respond(phone) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__phone:link,
  &__phone:visited {
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    color: $color-white;

    @include respond(tab-land) {
      font-size: 2rem;
    }

    &-text {
      @include respond(phone-large) {
        font-size: 0;
      }
    }
  }

  &__gallery:link,
  &__gallery:visited {
    &:hover {
      background-color: white;
      color: $color-primary;
    }
  }
}

.quote-btn-container {
  display: flex;
  align-items: center;
  align-self: center;
  border-radius: 10rem;

  &__fixed {
    position: relative;
    z-index: 1000;
  }
}

.quote-request-btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 2rem;
    width: auto;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: 2rem;
    font-family: $default-font;
    background-color: $color-primary;
    color: $color-white;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }
  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }
  &:active,
  &:focus {
    outline: none;
    transform: translateY(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
  &--animated {
    animation: moveInUp 1s ease-out 0.75s;
    animation-fill-mode: backwards;
  }
}
