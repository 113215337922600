@font-face {
  font-family: Josefin;
  src: url("JosefinSans-SemiBold.4d9019d3.ttf");
}

@font-face {
  font-family: Satisfy;
  src: url("Satisfy-Regular.11c49f83.ttf");
}

body, .header {
  font-family: Arial, Helvetica, sans-serif;
}

.heading-secondary {
  text-transform: uppercase;
  background-image: linear-gradient(to right, #5d5dfa, #01012c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: #0000;
  letter-spacing: .2rem;
  font-family: Josefin, sans-serif;
  font-size: 3.5rem;
  font-style: italic;
  font-weight: 700;
  transition: all 2s;
}

.heading-secondary:hover {
  text-shadow: .5rem 1rem 2rem #0003;
  transform: scaleX(1.2)scaleY(1.1);
}

.white {
  color: #fff;
}

*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 55%;
  }
}

body {
  box-sizing: border-box;
  background-color: #00008b;
}

.u-center-text {
  text-align: center;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
}

.u-margin-bottom-large {
  margin-bottom: 8rem;
}

.u-margin-top-small {
  margin-top: 1.5rem;
}

.u-margin-top-medium {
  margin-top: 4rem;
}

.u-margin-top-large {
  margin-top: 8rem;
}

.u-margin-top-huge {
  margin-top: 10rem;
}

.u-padding-top-small {
  padding-top: 1.5rem;
}

.u-padding-top-medium {
  padding-top: 4rem;
}

.u-padding-top-large {
  padding-top: 8rem;
}

.u-padding-top-huge {
  padding-top: 18rem;
}

.u-padding-bottom-small {
  padding-bottom: 1.5rem;
}

.u-padding-bottom-medium {
  padding-bottom: 4rem;
}

.u-padding-bottom-large {
  padding-bottom: 8rem;
}

.u-padding-bottom-huge {
  padding-bottom: 16rem;
}

.hidden {
  opacity: 0;
  transform: translateY(2rem);
}

.hidden-quote {
  height: 0;
  width: 0;
  display: hidden;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(7rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-7rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInDown {
  0% {
    opacity: 0;
    transform: translateY(-9rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveInUp {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes leftCenterZoom {
  0% {
    transform: translateX(0)scale(1);
  }

  100% {
    transform: translateX(50%)scale(1.5);
  }
}

@keyframes navSlide {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes dropDown {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100vh);
  }

  100% {
    transform: translateY(0);
  }
}

.card {
  perspective: 150rem;
  height: 70vh;
  width: 30rem;
  z-index: 1001;
  position: relative;
}

@media only screen and (max-width: 75em) {
  .card {
    height: 50rem;
  }
}

.card__side {
  height: 70vh;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 3px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: all .8s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem #00000026;
  -webkit-backface-visibility: hidden !important;
}

@media only screen and (max-width: 75em) {
  .card__side {
    height: 50rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.card__side--front {
  background-color: #fff;
}

.card__side--front-1 {
  background-image: linear-gradient(to bottom right, #dadada00, #dadada00), url("IMG_0264.f3dc5a82.jpg");
  background-position: center;
  background-size: cover;
}

.card__side--front-2 {
  background-image: linear-gradient(to bottom right, #dadada00, #dadada00), url("IMG_1018.571eb3eb.jpg");
  background-position: center;
  background-size: cover;
}

.card__side--front-3 {
  background-image: linear-gradient(to bottom right, #dadada00, #dadada00), url("IMG_0215.17767818.jpg");
  background-position: center;
  background-size: cover;
}

.card__side--back {
  height: 70vh;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0;
  display: flex;
  transform: rotateY(180deg);
}

@media only screen and (max-width: 75em) {
  .card__side--back {
    height: 50rem;
  }
}

.card__side--back-1 {
  background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3), url("IMG_0264.f3dc5a82.jpg");
}

@media only screen and (max-width: 75em) {
  .card__side--back-1 {
    background-color: #0000;
    background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3);
  }
}

.card__side--back-2 {
  background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3), url("IMG_1018.571eb3eb.jpg");
}

@media only screen and (max-width: 75em) {
  .card__side--back-2 {
    background-color: #0000;
    background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3);
  }
}

.card__side--back-3 {
  background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3), url("IMG_0215.17767818.jpg");
}

@media only screen and (max-width: 75em) {
  .card__side--back-3 {
    background-color: #0000;
    background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3);
  }
}

.card:hover .card__side--front {
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  transform: rotateY(0);
}

.card__picture {
  height: 23rem;
  background-blend-mode: screen;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card__picture--1, .card__picture--2, .card__picture--3 {
  background-color: #90ee90d9;
}

.card__heading {
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  width: 75%;
  font-size: 2.8rem;
  font-weight: 300;
  position: absolute;
  top: 12rem;
  right: 2rem;
}

.card__heading-span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 1rem 1.5rem;
}

.card__heading-span--1 {
  background-image: linear-gradient(to bottom right, #90ee90d9, #006400d9);
}

.card__heading-span--2 {
  background-image: linear-gradient(to bottom right, #5d5dfad9, #01012cd9);
}

.card__heading-span--3 {
  background-image: linear-gradient(to bottom right, #ffb6c1d9, #ffc0cbd9);
}

.card__details {
  padding: 3rem;
}

.card__details ul {
  width: 80%;
  margin: 0 auto;
  list-style: none;
}

.card__details ul li {
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem;
}

.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #a8a8a8;
}

.card__cta {
  width: 90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}

.card__price-only {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}

@media only screen and (max-width: 75em) {
  .card {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem #00000026;
  }

  .card__side {
    box-shadow: none;
    position: relative;
  }

  .card__side--back {
    clip-path: polygon(0 17rem, 100% 15rem, 100% 35rem, 0 35rem);
    margin-top: -36rem;
    padding-bottom: 1rem;
    transform: rotateY(0);
  }

  .card:hover .card__side--front {
    transform: rotateY(0);
  }

  .card__details {
    padding: 3rem;
  }

  .card__cta {
    width: 100%;
    padding: 7rem 4rem 4rem;
    position: relative;
    top: 0%;
    left: 0;
    transform: translate(0);
  }

  .card__price-box {
    margin-bottom: 3rem;
  }

  .card__price-value {
    font-size: 6rem;
  }
}

.collage__photo {
  height: auto;
  width: 30%;
  border-radius: 5px;
  transition: all .5s;
}

.collage__photo:hover {
  transform: scale(1.5);
  box-shadow: 0 0 2rem #000;
}

.footer {
  color: #a8a8a8;
  z-index: 1003;
  background-color: #1a1a1a;
  padding: 4rem 0;
  font-size: 4rem;
  position: relative;
}

@media only screen and (max-width: 65.625em) {
  .footer {
    margin-bottom: 4rem;
  }
}

.footer__site-map, .footer__certifications {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.6rem;
}

.footer__social-media {
  text-align: center;
  height: auto;
  width: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.footer__social-media--logo:hover {
  fill: #fff;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

.row .col-1-of-2 {
  width: calc(50% - 3rem);
}

.row .col-1-of-3 {
  width: calc(33.3333% - 4rem);
}

.row .col-2-of-3 {
  width: calc(66.6667% - 2rem);
}

.row .col-1-of-4 {
  width: calc(25% - 4.5rem);
}

.row .col-2-of-4 {
  width: calc(50% - 3rem);
}

.row .col-3-of-4 {
  width: calc(75% - 1.5rem);
}

.header {
  color: #fff;
  height: 15vh;
  width: 100vw;
  text-align: center;
  z-index: 1002;
  text-shadow: 0 1px 7px #000;
  background: linear-gradient(#00008b, #5d5dfa);
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: fixed;
  top: 0;
}

@media only screen and (max-width: 37.5em) {
  .header {
    height: auto;
    flex-flow: column wrap;
    padding-top: 1rem;
  }
}

.header__logo {
  color: #fff;
  margin: 0 1.5rem;
  text-decoration: none;
  animation: 1.1s ease-out moveInDown;
  display: inline-block;
  position: relative;
}

.header__logo-name {
  border: .5rem solid #fff;
  padding: 1rem .5rem .2rem;
  font-family: Josefin, sans-serif;
  font-size: 3.2rem;
}

@media only screen and (max-width: 65.625em) {
  .header__logo-name {
    font-size: 2.5rem;
  }
}

.header__logo-description {
  padding-top: .6rem;
  font-family: Josefin, sans-serif;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 500;
}

@media only screen and (max-width: 65.625em) {
  .header__logo-description {
    font-size: 1.2rem;
  }
}

.header__icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: #a8a8a8;
  margin-right: 1rem;
}

.landing-page {
  text-align: center;
  background-image: linear-gradient(to right, #5d5dfaf2, #00008bf2), url("IMG_0006.d7377914.jpg");
  flex-direction: column;
  align-items: center;
  display: flex;
}

.head-nav {
  display: flex;
}

@media only screen and (max-width: 65.625em) {
  .head-nav {
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.head-nav__item {
  position: relative;
}

.head-nav__item:not(:last-child) {
  margin-bottom: .5rem;
}

.head-nav__item:before {
  content: "";
  height: 100%;
  width: 3px;
  transition: background-color .1s;
  position: absolute;
  top: 0;
  left: 0;
}

.head-nav__item:active:before {
  background-color: #5d5dfa;
}

.head-nav__link:link, .head-nav__link:visited {
  color: #fff;
  text-transform: uppercase;
  z-index: 10;
  align-items: center;
  padding: 1.5rem 3rem;
  font-size: 2rem;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 65.625em) {
  .head-nav__link:link, .head-nav__link:visited {
    padding: 1.5rem 2rem;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 50.5em) {
  .head-nav__link:link:not(:last-child), .head-nav__link:visited:not(:last-child) {
    display: none;
  }
}

@media only screen and (max-width: 37.5em) {
  .head-nav__link:link, .head-nav__link:visited {
    padding: 1rem;
    font-size: 1.5rem;
  }
}

.head-nav__link:link:hover, .head-nav__link:visited:hover {
  background-color: #5d5dfa;
  transform: translateY(-.1rem);
}

.head-nav__icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  margin-right: 1rem;
}

@media only screen and (max-width: 37.5em) {
  .head-nav__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.head-nav__phone:link, .head-nav__phone:visited {
  color: #fff;
  align-items: center;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

@media only screen and (max-width: 75em) {
  .head-nav__phone:link, .head-nav__phone:visited {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 50.5em) {
  .head-nav__phone:link-text, .head-nav__phone:visited-text {
    font-size: 0;
  }
}

.head-nav__gallery:link:hover, .head-nav__gallery:visited:hover {
  color: #00008b;
  background-color: #fff;
}

.quote-btn-container {
  border-radius: 10rem;
  align-self: center;
  align-items: center;
  display: flex;
}

.quote-btn-container__fixed {
  z-index: 1000;
  position: relative;
}

.quote-request-btn, .quote-request-btn:link, .quote-request-btn:visited {
  text-transform: uppercase;
  width: auto;
  color: #fff;
  cursor: pointer;
  background-color: #00008b;
  border: none;
  border-radius: 10rem;
  padding: 1rem 2rem;
  font-family: Josefin, sans-serif;
  font-size: 2rem;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.quote-request-btn:hover {
  transform: translateY(-.3rem);
  box-shadow: 0 1rem 2rem #0003;
}

.quote-request-btn:hover:after {
  opacity: 0;
  transform: scaleX(1.4)scaleY(1.6);
}

.quote-request-btn:active, .quote-request-btn:focus {
  outline: none;
  transform: translateY(-.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

.quote-request-btn:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10rem;
  transition: all .4s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.quote-request-btn--animated {
  animation: 1s ease-out .75s backwards moveInUp;
}

.quote-container {
  z-index: 2000;
  background-image: url("IMG_0581.7bade5bd.jpg");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.quote {
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.quote__form {
  width: 100%;
  max-width: 100%;
  height: 75vh;
}

@media only screen and (max-width: 65.625em) {
  .quote__form {
    height: 100vh;
  }
}

.quote__form--input-align {
  flex-direction: column;
  display: flex;
}

@media only screen and (max-height: 25em) {
  .quote__form--input-align {
    flex-direction: row;
    gap: 2rem;
  }
}

.form {
  height: 100%;
  background-color: #fff9;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  display: flex;
}

.form__group:not(:last-child) {
  margin-bottom: .5rem;
}

.form__input {
  color: inherit;
  width: 90%;
  min-width: 30rem;
  background-color: #ffffffe6;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 3rem;
  padding: 1rem 2rem;
  font-family: Josefin, sans-serif;
  font-size: 1.5rem;
  transition: all .3s;
  display: block;
}

.form__input:focus {
  border: 3px solid #00008b;
  outline: none;
  box-shadow: 0 1rem 2rem #0000001a;
}

.form__input:focus:invalid {
  border: 3px solid red;
}

@media only screen and (max-width: 65.625em) {
  .form__input {
    padding: .6rem 1.2rem;
    font-size: 1rem;
  }
}

.form__label {
  margin-top: .7rem;
  margin-left: 2rem;
  font-family: Josefin, sans-serif;
  font-size: 1.4rem;
  transition: all .3s;
  display: block;
}

@media only screen and (max-width: 65.625em) {
  .form__label {
    font-size: 1rem;
  }
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  transform: translateY(-4rem);
}

.form__checkbox-group {
  margin-bottom: .8rem;
}

.form__checkbox-label {
  font-family: Josefin, sans-serif;
  font-size: 2rem;
}

@media only screen and (max-width: 65.625em) {
  .form__checkbox-label {
    font-size: 1.2rem;
  }
}

.form__checkbox-button {
  margin-right: 1rem;
}

.form__btns {
  justify-content: space-evenly;
  display: flex;
}

@media only screen and (max-height: 25em) {
  .form__btns {
    justify-content: center;
    gap: 1rem;
  }
}

.form__submit-btn, .form__back-btn {
  justify-content: center;
  display: flex;
}

.submit-btn, .submit-btn:link, .submit-btn:visited {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-color: #00008b;
  border: none;
  border-radius: 10rem;
  padding: 1rem 2rem;
  font-family: Josefin, sans-serif;
  font-size: 2rem;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.submit-btn:hover {
  transform: translateY(-.3rem);
  box-shadow: 0 1rem 2rem #0003;
}

.submit-btn:hover:after {
  opacity: 0;
  transform: scaleX(1.4)scaleY(1.6);
}

.submit-btn:active, .submit-btn:focus {
  outline: none;
  transform: translateY(-.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

.submit-btn:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10rem;
  transition: all .4s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.submit-btn--animated {
  animation: 1s ease-out .75s backwards moveInUp;
}

.back-btn {
  color: #1a1a1a;
  background-color: #dadada;
}

.section__aboutus {
  border: 1px;
  justify-content: center;
  align-items: center;
  padding: 0 5rem;
  display: flex;
}

.section__services {
  width: 100%;
  max-width: 100rem;
}

.section__services--title {
  margin-top: calc(15vh + 0rem + 2rem);
}

@media only screen and (max-width: 65.625em) {
  .section__services {
    max-width: 80rem;
  }
}

.aboutus-container {
  height: 70vh;
  width: 100%;
  max-width: 100rem;
  background-color: #fff;
  background-image: linear-gradient(to bottom right, #dadada00, #dadada00), url("IMG_0003-lg.53cfff34.jpg");
  background-position: center;
  background-size: cover;
  justify-content: flex-end;
  margin-top: calc(15vh + 2rem);
  display: flex;
  position: relative;
  box-shadow: 0 0 10px #1a1a1a;
}

@media only screen and (max-width: 75em) {
  .aboutus-container {
    height: 45rem;
    margin-top: calc(15vh + 4rem);
  }
}

@media only screen and (max-width: 37.5em) {
  .aboutus-container {
    height: 60rem;
    justify-content: center;
    align-items: center;
  }
}

.aboutus-container__photo {
  height: auto;
  width: 50%;
}

@media only screen and (max-width: 65.625em) {
  .aboutus-container__photo {
    width: 75%;
    margin-bottom: 2rem;
  }
}

.aboutus-container__photo--file {
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  position: relative;
  overflow: clip;
}

@media only screen and (max-width: 50.5em) {
  .aboutus-container__photo {
    display: none;
  }
}

.aboutus-container__text {
  width: 60%;
  height: 100%;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  background-image: linear-gradient(to bottom right, #dadadacc, #dadadacc);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20% 10%;
  display: flex;
}

.aboutus-container__text--title {
  text-transform: uppercase;
  margin-bottom: 4rem;
  font-family: Josefin, sans-serif;
  font-size: 4rem;
}

.aboutus-container__text--paragraph {
  text-align: justify;
  font-family: Satify, Josefin, sans-serif;
  font-size: 2.2rem;
  font-style: italic;
}

@media only screen and (max-width: 37.5em) {
  .aboutus-container__text {
    height: 40rem;
    width: 100%;
    clip-path: polygon(0 5rem, 100% 3rem, 100% 38rem, 0 100%);
  }
}

.services {
  max-width: 100rem;
  align-items: center;
  margin: 3rem 0;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .services {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.services__heading {
  text-transform: uppercase;
  color: #000;
  width: 100%;
  height: 25%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  background-image: linear-gradient(to bottom right, #dadadacc, #dadadacc);
  padding: 2rem;
  font-family: Josefin, sans-serif;
  font-size: 2.2rem;
}

.services__heading--back {
  height: auto;
  background-image: none;
}

.services__container {
  width: 100%;
  justify-content: space-evenly;
  transition: all .3s ease-in;
  display: flex;
}

@media only screen and (max-width: 75em) {
  .services__container {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .services__container {
    width: 90%;
    margin-bottom: 2rem;
  }
}

.services__photo {
  max-width: 90%;
  height: 45vh;
  width: 30rem;
  border-radius: 2px;
}

.services__text {
  margin: 2rem;
  font-family: Satify, Josefin, sans-serif;
  font-size: 2rem;
}

@media only screen and (max-width: 75em) {
  .services__text {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .services__text {
    text-align: center;
    font-family: Josefin, sans-serif;
    font-style: normal;
  }
}

.other-services {
  justify-content: center;
  margin: 2rem 0;
  display: flex;
}

.other-services__list {
  padding: 2rem;
  list-style: none;
}

@media only screen and (max-width: 37.5em) {
  .other-services__list {
    width: 90%;
    margin-top: -2rem;
  }
}

.other-services__list--item {
  font-family: Satify, Josefin, sans-serif;
  font-size: 2rem;
}

@media only screen and (max-width: 75em) {
  .other-services__list--item {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .other-services__list--item {
    text-align: center;
    font-family: Josefin, sans-serif;
    font-style: normal;
  }
}

.tagline-container {
  min-height: 85vh;
  height: auto;
  background-image: linear-gradient(to bottom right, #dadadab3, #dadadab3), url("IMG_3265.6ea47016.jpg");
  background-position: 0 25%;
  background-size: cover;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15vh;
  display: flex;
}

@media only screen and (max-width: 75em) {
  .tagline-container {
    flex-direction: column;
  }
}

.tagline-container__text {
  min-width: 50%;
  text-align: center;
  color: #00008b;
  text-shadow: 0 0 10px #1a1a1a;
  align-self: center;
  font-family: Satify, Josefin, sans-serif;
  font-size: 3.5rem;
  font-style: italic;
}

.tagline-container__collage {
  max-width: 40%;
  justify-content: center;
  display: flex;
}

.tagline-container__collage--photo {
  width: 20%;
  border-radius: 2px;
  transition: all .2s;
  position: relative;
  box-shadow: 0 1.5rem 4rem #0006;
}

.tagline-container__collage--photo:hover {
  z-index: 20;
  transform: scale(1.4)translateY(-.5rem);
  box-shadow: 0 2.5rem 4rem #00000080;
}

.tagline-container__collage:hover .tagline-container__collage__photo:not(:hover) {
  transform: scale(.6);
}

.picture-border {
  margin-left: 24rem;
  display: flex;
}

.picture-border__photo {
  width: 100%;
  max-height: 25rem;
  justify-content: center;
  display: block;
}

/*# sourceMappingURL=index.7c8b9f41.css.map */
