//////////////////////// Import fonts

@font-face {
  font-family: 'Josefin';
  src: url(./../assets/fonts/JosefinSans/JosefinSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Satisfy';
  src: url(./../assets/fonts/Satisfy/Satisfy-Regular.ttf);
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  font-family: Arial, Helvetica, sans-serif;
}

.heading-secondary {
  font-size: 3.5rem;
  font-family: 'Josefin', sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  background-image: linear-gradient(
    to right,
    $color-primary-light,
    $color-primary-dark
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 2s;

  &:hover {
    transform: scaleX(1.2) scaleY(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }
}

.white {
  color: $color-white;
}
