.card {
  //Functionality
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 70vh;
  width: 30rem;
  z-index: 1001;

  @include respond(tab-land) {
    height: 50rem;
  }

  &__side {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 70vh;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);

    @include respond(tab-land) {
      justify-content: space-between;
      margin-bottom: 1rem;
      height: 50rem;
    }

    &--front {
      background-color: $color-white;

      &-1 {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0),
            rgba($color-lightest-grey, 0)
          ),
          url(../assets/images/IMG_0264.jpg);

        background-position: center;
        background-size: cover;
      }

      &-2 {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0),
            rgba($color-lightest-grey, 0)
          ),
          url(../assets/images/IMG_1018.jpg);

        background-position: center;
        background-size: cover;
      }

      &-3 {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0),
            rgba($color-lightest-grey, 0)
          ),
          url(../assets/images/IMG_0215.jpg);

        background-position: center;
        background-size: cover;
      }
    }

    &--back {
      background-color: $color-white;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0;
      background-position: center;
      background-size: cover;
      // -webkit-backface-visibility: hidden;
      // backface-visibility: hidden;

      @include respond(tab-land) {
        height: 50rem;
      }

      &-1 {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0.7),
            rgba($color-lightest-grey, 0.7)
          ),
          url(../assets/images/IMG_0264.jpg);

        @include respond(tab-land) {
          background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0.7),
            rgba($color-lightest-grey, 0.7)
          );
          background-color: transparent;
        }
      }

      &-2 {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0.7),
            rgba($color-lightest-grey, 0.7)
          ),
          url(../assets/images/IMG_1018.jpg);
        @include respond(tab-land) {
          background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0.7),
            rgba($color-lightest-grey, 0.7)
          );
          background-color: transparent;
        }
      }

      &-3 {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0.7),
            rgba($color-lightest-grey, 0.7)
          ),
          url(../assets/images/IMG_0215.jpg);
        @include respond(tab-land) {
          background-image: linear-gradient(
            to right bottom,
            rgba($color-lightest-grey, 0.7),
            rgba($color-lightest-grey, 0.7)
          );
          background-color: transparent;
        }
      }
    }
  }

  &:hover &__side--front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  // FRONT SIDE STYLING

  &__picture {
    background-size: cover;
    height: 23rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &--1 {
      background-color: rgba($color-secondary-light, 0.85);
    }

    &--2 {
      background-color: rgba($color-secondary-light, 0.85);
    }

    &--3 {
      background-color: rgba($color-secondary-light, 0.85);
    }
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: $color-white;
    position: absolute;
    top: 12rem;
    right: 2rem;
    width: 75%;
  }

  &__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    &--1 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-secondary-light, 0.85),
        rgba($color-secondary-dark, 0.85)
      );
    }

    &--2 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.85),
        rgba($color-primary-dark, 0.85)
      );
    }

    &--3 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-tertiary-light, 0.85),
        rgba($color-tertiary-dark, 0.85)
      );
    }
  }

  &__details {
    padding: 3rem;

    ul {
      list-style: none;
      width: 80%;
      margin: 0 auto;

      li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid $color-grey-light-2;
        }
      }
    }
  }

  // FRONT SIDE STYLING
  &__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }

  &__price-box {
    text-align: center;
    color: $color-white;
    margin-bottom: 8rem;
  }

  &__price-only {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  &__price-value {
    font-size: 6rem;
    font-weight: 100;
  }

  @include respond(tab-land) {
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);

    &__side {
      // height: 85%;
      position: relative;
      box-shadow: none;

      &--back {
        transform: rotateY(0);
        margin-top: -36rem;
        clip-path: polygon(0 17rem, 100% 15rem, 100% 35rem, 0 35rem);
        padding-bottom: 1rem;
      }
    }

    &:hover &__side--front {
      transform: rotateY(0);
    }

    &__details {
      padding: 3rem;
    }

    // FRONT SIDE STYLING
    &__cta {
      position: relative;
      top: 0%;
      left: 0;
      transform: translate(0);
      width: 100%;
      padding: 7rem 4rem 4rem 4rem;
    }

    &__price-box {
      margin-bottom: 3rem;
    }

    &__price-value {
      font-size: 6rem;
    }
  }
}
