.u-center-text {
  text-align: center;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem;
}
.u-margin-bottom-large {
  margin-bottom: 8rem;
}

.u-margin-top-small {
  margin-top: 1.5rem;
}
.u-margin-top-medium {
  margin-top: 4rem;
}
.u-margin-top-large {
  margin-top: 8rem;
}
.u-margin-top-huge {
  margin-top: 10rem;
}

.u-padding-top-small {
  padding-top: 1.5rem;
}
.u-padding-top-medium {
  padding-top: 4rem;
}
.u-padding-top-large {
  padding-top: 8rem;
}
.u-padding-top-huge {
  padding-top: 18rem;
}

.u-padding-bottom-small {
  padding-bottom: 1.5rem;
}
.u-padding-bottom-medium {
  padding-bottom: 4rem;
}
.u-padding-bottom-large {
  padding-bottom: 8rem;
}
.u-padding-bottom-huge {
  padding-bottom: 16rem;
}

.hidden {
  opacity: 0;
  transform: translateY(2rem);
}

.hidden-quote {
  height: 0;
  width: 0;
  display: hidden;
}
