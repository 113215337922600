.landing-page {
  background-image: linear-gradient(
      to right,
      rgba($color-primary-light, 0.95),
      rgba($color-primary, 0.95)
    ),
    url('../assets/images/IMG_0006.jpg');
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}
