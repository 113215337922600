.quote-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 2000;
  background-image: url(../assets/images/IMG_0581.jpg);
  background-size: cover;
  background-position: center;
}

.quote {
  overflow: hidden;
  display: flex;
  justify-content: center;

  &__form {
    width: 100%;
    max-width: 100%;
    height: 75vh;

    @include respond(tab-port) {
      height: 100vh;
    }

    &--input-align {
      display: flex;
      flex-direction: column;

      @include respond(horiz-phone) {
        flex-direction: row;
        gap: 2rem;
      }
    }
  }
}

.form {
  background-color: rgba($color-white, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  // width: 100vw;
  padding: 2rem;

  &__group:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: $default-font;
    color: inherit;
    padding: 1rem 2rem;
    border-radius: 3rem;
    background-color: rgba($color-white, 0.9);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    min-width: 30rem;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border: 3px solid $color-primary;
    }

    &:focus:invalid {
      border: 3px solid $color-invalid;
    }

    @include respond(tab-port) {
      font-size: 1rem;
      padding: 0.6rem 1.2rem;
    }
  }

  &__label {
    font-family: $default-font;
    font-size: 1.4rem;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;

    @include respond(tab-port) {
      font-size: 1rem;
    }
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    // visibility: hidden;
    transform: translateY(-4rem);
  }

  &__checkbox-group {
    margin-bottom: 0.8rem;
  }

  &__checkbox-label {
    font-family: $default-font;
    font-size: 2rem;

    @include respond(tab-port) {
      font-size: 1.2rem;
    }
  }

  &__checkbox-button {
    margin-right: 1rem;
  }

  &__btns {
    display: flex;
    justify-content: space-evenly;

    @include respond(horiz-phone) {
      justify-content: center;
      gap: 1rem;
    }
  }

  &__submit-btn {
    display: flex;
    justify-content: center;
  }

  &__back-btn {
    display: flex;
    justify-content: center;
  }
}

.submit-btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 2rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: 2rem;
    font-family: $default-font;
    background-color: $color-primary;
    color: $color-white;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }
  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }
  &:active,
  &:focus {
    outline: none;
    transform: translateY(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
  &--animated {
    animation: moveInUp 1s ease-out 0.75s;
    animation-fill-mode: backwards;
  }
}

.back-btn {
  background-color: $color-lightest-grey;
  color: $color-dark-grey;
}
