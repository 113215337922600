@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(7rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-7rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveInDown {
  0% {
    opacity: 0;
    transform: translateY(-9rem);
  }
  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

@keyframes moveInUp {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes leftCenterZoom {
  0% {
    transform: translateX(0) scale(1);
  }

  100% {
    transform: translateX(50%) scale(1.5);
  }
}

@keyframes navSlide {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes dropDown {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100vh);
  }

  100% {
    transform: translateY(0);
  }
}
